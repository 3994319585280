<template>
  <div class="mt-2">
    <Header />
    <div class="page-content">
      <section id="contact" ref="contact" class="contact zero-top">
        <div class="container">
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col md="12" lg="12" sm="12" class=" ">
              <b-row class=" ">
                <b-col cols="12" md="12" lg="12" sm="12">
                  <h3 class="sectio-title">
                    {{ $t("ContactUs.ContactUsTitle") }}
                  </h3>
                  <p class="card-text mt-4" style="text-align: center">
                    {{ $t("ContactUs.Title") }}
                    <a href="#" class="ForSupportQueriesEmail">
                      {{ $t("ContactUs.contactUs") }}
                      <!-- <i class="bx bx-chevron-right"></i
                    > -->
                    </a>
                  </p>
                </b-col>
              </b-row>

              <b-card-text title-tag="h2" class="title"></b-card-text>

              <b-row class="align-items-center mt-4">
                <b-col cols="12" lg="3" sm="12"></b-col>
                <b-col cols="12" lg="6" sm="12" class="contact-svg">
                  <Contact />
                </b-col>
                <!-- <b-col cols="12" lg="6" sm="12">
                  <div class="cardborder margincard m-4 align-items-center">
                    <div
                      class="d-flex info-item align-items-center justify-content-center mt-4 mb-4 cotact-text"
                    >
                      <span>{{ $t("ContactUs.ForSupportQueries") }}</span>
                    </div>
                  </div>
                </b-col> -->
              </b-row>
            </b-col>
          </b-row>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import {
  BButton,
  BImg,
  BNavbarNav,
  BNavbarToggle,
  BNavbar,
  BCollapse,
  BRow,
  BCol,
  BCard,
  BCardText
} from "bootstrap-vue";
import store from "@/store/index";
import Locale from "@/layouts/components/Locale.vue";
import Contact from "@/assets/images/pages/Contact.svg";
import Logo from "@/assets/rashiCoin/logo.svg";
import Header from "./../Header.vue";
import Footer from "./../Footer.vue";
export default {
  components: {
    BButton,
    BImg,
    BNavbarNav,
    BNavbarToggle,
    BNavbar,
    BCollapse,
    Locale,
    BRow,
    BCol,
    BCard,
    BCardText,
    Contact,
    Logo,
    Header,
    Footer
  },
  data() {
    return {
      isActive: 1,
      lang: this.$route.params.lang
    };
  },
  mounted() {},
  methods: {
    changeTab(tab) {
      this.isActive = tab;

      if (tab === 2) {
        document.querySelector(".tab-slider--tabs").classList.add("slide");
      } else {
        document.querySelector(".tab-slider--tabs").classList.remove("slide");
      }
    },
    openPage(props) {
      this.$router.push({
        name: props,
        params: { lang: this.lang || undefined }
      });
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        return this.downImg;
      }
      return this.downImg;
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/landing/css/style.scss";
@import "@core/scss/vue/pages/page-misc.scss";
</style>
<style lang="scss" scoped>
.page-content {
  min-height: 90vh;
  padding-top: 0px;
}
.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px 90px;
}
.services .card h3.without-img {
  margin-top: 0px;
}
.full-size {
  width: 100%;
}
.desc.text-left.full-size {
  margin-bottom: 25px;
}
.ForSupportQueriesEmail {
  margin-left: 5px;
}
</style>
